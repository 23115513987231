import BaseService from "./BaseService";
import ServerError from "./errors/ServerError";

export default class UserService extends BaseService {
  static async getMe() {
    try {
      const result = await this.request({auth: true}).get("/auth/me");

      return result.data;
    } catch (error) {
      if (error.response) {
        throw new ServerError(error.message, error.response.status);
      }

      throw error;
    }
  }

  static async update(id, data) {
    try {
      const result = await this.request({auth: true}).put(`/admin/users/${id}`, data);

      return result.data;
    } catch (error) {
      if (error.response) {
        throw new ServerError(error.message, error.response.status);
      }

      throw error;
    }
  }

  
}
