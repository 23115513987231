<template>
  <div>
    <span v-for="(link, index) in links" :key="index">
      <RouterLink :to="{ name: link.route, params: link.params != undefined ? {...link.params} : null}">
        {{ link.name }}
      </RouterLink>

      {{ index < links.length - 1 ? " / " : "" }}
    </span>
  </div>
</template>

<script>
export default {
  name: "Breadcrumb",
  props: ["links"],
  setup() {
    return {};
  },
};
</script>

<style lang="scss" scoped></style>
