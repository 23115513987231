import { h, resolveComponent } from "vue";
import { createRouter, createWebHistory } from "vue-router";

import DefaultLayout from "@/layouts/DefaultLayout";
import { useUserStore } from "@/store/modules/user";
import { TOKEN_NAME, USER_NAME } from "@/configs";

const routes = [
  {
    path: "/500",
    name: "page500",
    meta: {
      requiresAuth: false,
    },
    component: () => import("@/views/pages/errors/Page500"),
  },
  {
    path: "/auth",
    name: "auth",
    meta: {
      requiresAuth: false,
    },
    component: () => import("@/views/pages/auth/Auth"),
  },
  {
    path: "/register",
    name: "register",
    meta: {
      requiresAuth: false,
    },
    component: () => import("@/views/pages/auth/Register"),
  },
  {
    path: "/reset-password",
    name: "reset.password",
    meta: {
      requiresAuth: false,
    },
    component: () => import("@/views/pages/auth/ResetPassword"),
  },

  {
    path: "/:pathMatch(.*)*",
    name: "page404",
    meta: {
      requiresAuth: false,
    },
    component: () => import("@/views/pages/errors/Page404"),
  },

  {
    path: "/home",
    name: "home",
    component: DefaultLayout,
    redirect: "/",
    meta: {
      requiresAuth: true,
    },
    children: [
      {
        path: "/",
        name: "",
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () =>
          import(
            /* webpackChunkName: "dashboard" */ "@/views/pages/Dashboard.vue"
          ),
      },
      {
        path: "/profile/edit",
        name: "profile.edit",
        component: () => import("@/views/pages/profile/Edit.vue"),
      },
      {
        path: "/clients",
        name: "clients",
        component: () => import("@/views/pages/clients/Index.vue"),
      },

      {
        path: "/clients/new",
        name: "clients.new",
        component: () => import("@/views/pages/clients/Create.vue"),
      },
      {
        path: "/clients/edit/:id",
        name: "clients.edit",
        component: () => import("@/views/pages/clients/Edit.vue"),
      },
      {
        path: "/clients/settings/:id",
        name: "clients.settings",
        component: () => import("@/views/pages/clients/Settings.vue"),
      },
      {
        path: "/clients/domain/:id",
        name: "clients.domain",
        component: () => import("@/views/pages/clients/Domain.vue"),
      },
      {
        path: "/clients/:id/report/billing",
        name: "clients.billing.report",
        component: () => import("@/views/pages/clients/report/BillingReport.vue"),
      },
      {
        path: "/influencers",
        name: "influencers",
        component: () => import("@/views/pages/influencers/Index.vue"),
      },
      {
        path: "/influencers/:id",
        name: "influencers.detail",
        component: () => import("@/views/pages/influencers/Detail.vue"),
      },
      {
        path: "/influencers/edit/:id",
        name: "influencers.edit",
        component: () => import("@/views/pages/influencers/Edit.vue"),
      },
      {
        path: "/supports",
        name: "supports",
        component: () => import("@/views/pages/supports/Index.vue"),
      },
      {
        path: "/supports/:id",
        name: "supports.details",
        component: () => import("@/views/pages/supports/Detail.vue"),
      },
      {
        path: "/leads",
        name: "leads",
        component: () => import("@/views/pages/leads/Index.vue"),
      },
      

      /*
      {
        path: '/buttons',
        name: 'buttons',
        component: {
          render() {
            return h(resolveComponent('router-view'))
          },
        },
        redirect: '/buttons/standard-buttons',
        children: [
          {
            path: '/buttons/standard-buttons',
            name: 'buttons',
            component: () => import('@/views/buttons/Buttons.vue'),
          },
          {
            path: '/buttons/dropdowns',
            name: 'dropdowns',
            component: () => import('@/views/buttons/Dropdowns.vue'),
          },
          {
            path: '/buttons/button-groups',
            name: 'button_groups',
            component: () => import('@/views/buttons/ButtonGroups.vue'),
          },
        ],
      },
      {
        path: '/forms',
        name: 'forms',
        component: {
          render() {
            return h(resolveComponent('router-view'))
          },
        },
        redirect: '/forms/form-control',
        children: [
          {
            path: '/forms/form-control',
            name: 'form_control',
            component: () => import('@/views/forms/FormControl.vue'),
          },
          {
            path: '/forms/select',
            name: 'select',
            component: () => import('@/views/forms/Select.vue'),
          },
          {
            path: '/forms/checks-radios',
            name: 'checks',
            component: () => import('@/views/forms/ChecksRadios.vue'),
          },
          {
            path: '/forms/range',
            name: 'range',
            component: () => import('@/views/forms/Range.vue'),
          },
          {
            path: '/forms/input-group',
            name: 'input_group',
            component: () => import('@/views/forms/InputGroup.vue'),
          },
          {
            path: '/forms/floating-labels',
            name: 'labels',
            component: () => import('@/views/forms/FloatingLabels.vue'),
          },
          {
            path: '/forms/layout',
            name: 'layout',
            component: () => import('@/views/forms/Layout.vue'),
          },
          {
            path: '/forms/validation',
            name: 'validation',
            component: () => import('@/views/forms/Validation.vue'),
          },
        ],
      },
      {
        path: '/charts',
        name: 'charts',
        component: () => import('@/views/charts/Charts.vue'),
      },
      {
        path: '/icons',
        name: 'icons',
        component: {
          render() {
            return h(resolveComponent('router-view'))
          },
        },
        redirect: '/icons/coreui-icons',
        children: [
          {
            path: '/icons/coreui-icons',
            name: 'coreui_icons',
            component: () => import('@/views/icons/CoreUIIcons.vue'),
          },
          {
            path: '/icons/brands',
            name: 'brands',
            component: () => import('@/views/icons/Brands.vue'),
          },
          {
            path: '/icons/flags',
            name: 'flags',
            component: () => import('@/views/icons/Flags.vue'),
          },
        ],
      },
      {
        path: '/notifications',
        name: 'notifications',
        component: {
          render() {
            return h(resolveComponent('router-view'))
          },
        },
        redirect: '/notifications/alerts',
        children: [
          {
            path: '/notifications/alerts',
            name: 'alerts',
            component: () => import('@/views/notifications/Alerts.vue'),
          },
          {
            path: '/notifications/badges',
            name: 'badges',
            component: () => import('@/views/notifications/Badges.vue'),
          },
          {
            path: '/notifications/modals',
            name: 'modals',
            component: () => import('@/views/notifications/Modals.vue'),
          },
        ],
      },
      {
        path: '/widgets',
        name: 'widgets',
        component: () => import('@/components/dashboard/Widgets.vue'),
      },
      */
    ],
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
  scrollBehavior() {
    // always scroll to top
    return { top: 0 };
  },
});

router.beforeEach(async (to, from) => {
  const token = await localStorage.getItem(TOKEN_NAME);
  const user = JSON.parse(await localStorage.getItem(USER_NAME));
  

  if (to.matched.some((record) => record.meta.requiresAuth)) {
    if (token && user != null) {
      const userStore = useUserStore();
      userStore.setToken(token);
    } else {
      if (to.name !== "auth") {
        return { name: "auth" };
      }
    }
  } else {
    if (token && user != null && user.permission == 'ADMIN' && to.name === "auth") {
 
      return { name: "home" };
    }
  }
});

export default router;
