<template>
  <CFooter class="mt-5">
    <div>
      <a href="http://www.lerigol.com.br" target="_blank">Lerigol</a>
      <span class="ms-1"
        >&copy; {{ new Date().getFullYear() }} Lerigol Tecnologia LTDA.</span
      >
    </div>
    <div class="ms-auto">
      <span class="me-1" target="_blank">Desenvolvido por</span>
      <a href="http://www.lerigol.com.br">Lerigol Tecnologia</a>
    </div>
  </CFooter>
</template>

<script>
export default {
  name: 'Footer',
}
</script>
