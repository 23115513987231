<template>
  <router-view />
</template>

<style>
@import "./assets/css/main.css";
@import url('https://cdnjs.cloudflare.com/ajax/libs/font-awesome/6.2.1/css/all.min.css');
</style>

<style lang="scss">
// Import Main styles for this application
@import "styles/style";
</style>


