<template>
  <CSidebar position="fixed" :unfoldable="sidebarUnfoldable" :visible="sidebarVisible" @visible-change="(event) =>
      globalStore.updateSidebarVisible

    ">
    <CSidebarBrand>
      <RouterLink :to="{ path: '/' }">
        <img src="@/assets/images/logo.svg" width="100">
      </RouterLink>

    </CSidebarBrand>
    <SidebarNav />

  </CSidebar>
</template>

<script>
import { computed } from 'vue'
import { SidebarNav } from './SidebarNav'
import { useGlobalStore } from '@/store/modules/global';

export default {
  name: 'Sidebar',
  components: {
    SidebarNav,
  },
  setup() {

    const globalStore = useGlobalStore();

    return {
      sidebarUnfoldable: computed(() => globalStore.sidebarUnfoldable),
      sidebarVisible: computed(() => globalStore.sidebarVisible),
      globalStore
    }
  },
}
</script>
