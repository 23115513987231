<template>
  <CDropdown variant="nav-item">
    <CDropdownToggle placement="bottom-end" class="py-0" :caret="false">
      <CAvatar :src="avatar" size="md" />
    </CDropdownToggle>
    <CDropdownMenu class="pt-0">
      <CDropdownHeader component="h6" class="bg-light fw-semibold py-2">
        Meu Perfil
      </CDropdownHeader>
      
      <RouterLink :to="{name: 'profile.edit'}">
        <CDropdownItem> <CIcon icon="cil-user" /> Meu Perfil </CDropdownItem>
      </RouterLink>
     
      
      
      <CDropdownDivider />
      
      <CDropdownItem @click="logout" class="cursor-pointer">
        <CIcon icon="cil-lock-locked" /> Sair
      </CDropdownItem>
    </CDropdownMenu>
  </CDropdown>
</template>

<script>
import avatar from "@/assets/images/avatars/8.jpg";
import { useUserStore } from "@/store/modules/user";
import { useGlobalStore } from "@/store/modules/global";
import { useRouter } from "vue-router";


export default {
  name: "HeaderDropdownAccount",
  setup() {
    const userStore = useUserStore();
    const globalStore = useGlobalStore();
    const router = useRouter();

    const logout = async () => {
      globalStore.enableLoading();

      userStore
        .logout()
        .then((logout) => {
       
          if (logout) {
            userStore.removeUser();
            router.replace({ name: "auth" });
          }
        })
        .finally(() => globalStore.disableLoading());
    };
    return {
      avatar: avatar,
      itemsCount: 42,
      logout,
    };
  },
};
</script>
