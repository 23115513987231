import { defineStore } from 'pinia'

export const useGlobalStore = defineStore('global', {
  state: () => {
    return {
    sidebarVisible: '',
    sidebarUnfoldable: false,
    loading: false,
    showDeleteModal: false,
    deleteId: null,
    breadcrumbLinks: []
    }
  },
  actions: {
    toggleSidebar() {
      this.sidebarVisible = !this.sidebarVisible
    },
    toggleUnfoldable() {
      this.sidebarUnfoldable = !this.sidebarUnfoldable
    },
    updateSidebarVisible(payload) {
      this.sidebarVisible = payload
    },
    enableLoading() {
      this.loading = true;
    },
    disableLoading() {
      this.loading = false;
    },
    toggleDeleteModal(deleteId){
      
      this.showDeleteModal = !this.showDeleteModal;
      if(this.showDeleteModal && deleteId !== undefined){
        this.deleteId = deleteId;
      }else{
        this.deleteId = null;
      }
    },
    setBreadcrumb(links){
      this.breadcrumbLinks = links;
    }
  }
})
