import BaseService from "./BaseService";
import ServerError from "./errors/ServerError";

export default class AuthService extends BaseService {
  static async auth(data) {
    try {
      const result = await this.request().post("/auth/login", data);

      return result.data.access_token;
    } catch (error) {
      if (error.data) {
        throw new ServerError(error.message, error.data.statusCode);
      }
      if (error.response) {
        throw new ServerError(error.message, error.response.status);
      }

      throw error;
    }
  }

  static async logout() {
    try {
      const result = await this.request({ auth: true }).post("/auth/logout");

      return result.data;
    } catch (error) {
      console.log(error);
      if (error.response) {
        throw new ServerError(error.message, error.response.status);
      }

      throw error;
    }
  }
}
