import AuthService from "@/services/AuthService";
import UserService from "@/services/UserService";
import { TOKEN_NAME, USER_NAME } from "@/configs";
import { defineStore } from "pinia";

export const Permissions = {
  ADMIN: "ADMIN",
  CLIENT: "CLIENT",
  INFLUENCER: "INFLUENCER",
};

export const useUserStore = defineStore("user", {
  state: () => {
    return {
      user: null,
      loggedIn: false,
    };
  },
  actions: {
    async authenticate(params) {
      return await AuthService.auth(params);
    },
    async getMe() {
      return await UserService.getMe();
    },
    async update(id, data) {
      return await UserService.update(id, data);
    },
    setToken(token) {
      localStorage.setItem(TOKEN_NAME,token);
      this.loggedIn = true;
    },
    setUser(user) {
      localStorage.setItem(USER_NAME, JSON.stringify(user));
    },
    getUser() {
      const user = JSON.parse(localStorage.getItem(USER_NAME));
      user.password = undefined;
      return user;
    },
    removeUser() {
      localStorage.removeItem(TOKEN_NAME);
    },
    async logout() {
      return await AuthService.logout();
    },
  },
});
