export default [
  {
    component: 'CNavItem',
    name: 'Home',
    to: '/',
    icon: 'fas fa-home',
    style: 'margin-right: 20px;'
  },
 
  {
    component: 'CNavItem',
    name: 'Clientes',
    to: '/clients',
    icon: 'fas fa-users',
    style: 'margin-right: 16px;'
    
  },
  {
    component: 'CNavItem',
    name: 'Influencers',
    to: '/influencers',
    icon: 'fas fa-hashtag',
    style: 'margin-right: 22px;'
    
  },
  {
    component: 'CNavItem',
    name: 'Chamados',
    to: '/supports',
    icon: 'fa-solid fa-headset',
    style: 'margin-right: 22px;'
    
  },
  {
    component: 'CNavItem',
    name: 'Leads',
    to: '/leads',
    icon: 'fas fa-funnel-dollar',
    style: 'margin-right: 22px;'
    
  },
  /*
  {
    component: 'CNavGroup',
    name: 'Forms',
    to: '/forms',
    icon: 'fas fa-users pe-4',
    items: [
      {
        component: 'CNavItem',
        name: 'Form Control',
        to: '/forms/form-control',
      },
      {
        component: 'CNavItem',
        name: 'Select',
        to: '/forms/select',
      },
      {
        component: 'CNavItem',
        name: 'Checks & Radios',
        to: '/forms/checks-radios',
      },
      {
        component: 'CNavItem',
        name: 'Range',
        to: '/forms/range',
      },
      {
        component: 'CNavItem',
        name: 'Input Group',
        to: '/forms/input-group',
      },
      {
        component: 'CNavItem',
        name: 'Floating Labels',
        to: '/forms/floating-labels',
      },
      {
        component: 'CNavItem',
        name: 'Layout',
        to: '/forms/layout',
      },
      {
        component: 'CNavItem',
        name: 'Validation',
        to: '/forms/validation',
      },
    ],
  },
  {
    component: 'CNavItem',
    name: 'Charts',
    to: '/charts',
    icon: 'fas fa-users'
  },
  {
    component: 'CNavGroup',
    name: 'Icons',
    to: '/icons',
    icon: 'fas fa-users',
    items: [
      {
        component: 'CNavItem',
        name: 'CoreUI Icons',
        to: '/icons/coreui-icons',
        badge: {
          color: 'info',
          text: 'NEW',
        },
      },
      {
        component: 'CNavItem',
        name: 'Brands',
        to: '/icons/brands',
      },
      {
        component: 'CNavItem',
        name: 'Flags',
        to: '/icons/flags',
      },
    ],
  },
  {
    component: 'CNavGroup',
    name: 'Notifications',
    to: '/notifications',
    icon: 'fas fa-users',
    items: [
      {
        component: 'CNavItem',
        name: 'Alerts',
        to: '/notifications/alerts',
      },
      {
        component: 'CNavItem',
        name: 'Badges',
        to: '/notifications/badges',
      },
      {
        component: 'CNavItem',
        name: 'Modals',
        to: '/notifications/modals',
      },
    ],
  },
  */
  
 
]
