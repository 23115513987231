<template>
  <div>
    <loading v-model:active="globalStore.loading" :is-full-page="true" />
   

    <AppSidebar />
    <div class="wrapper d-flex flex-column min-vh-100 bg-light">
      <AppHeader />
      <div class="body flex-grow-1 px-3">
        <CContainer lg>
          <router-view />
        </CContainer>
      </div>
      <AppFooter />
    </div>
  </div>
</template>
<script>
import { CContainer } from "@coreui/vue";
import AppFooter from "@/components/base/Footer.vue";
import AppHeader from "@/components/base/Header.vue";
import AppSidebar from "@/components/base/Sidebar.vue";
import Loading from "vue-loading-overlay";


import "vue-loading-overlay/dist/css/index.css";

import { useGlobalStore } from "@/store/modules/global";
import * as Yup from "yup";
import { pt } from "yup-locale-pt";
import { validaCpfCnpj } from '@/helpers/functions';

export default {
  name: "DefaultLayout",
  components: {
    AppFooter,
    AppHeader,
    AppSidebar,
    CContainer,
    Loading,
    
   
  },
  setup() {
    Yup.setLocale(pt);

    Yup.addMethod(Yup.string, "cnpj", function (message) {
      return this.test("isValidDocument", message, function (value) {
        const { path, createError } = this;

        if (!validaCpfCnpj(value) && value !== '') {
          return createError({
            path,
            message: message ?? 'Erro',
          });
        }

        return true;
      });
    });

    const globalStore = useGlobalStore();

    return {
      globalStore,
    };
  },
};
</script>
<style lang="scss">
.sidebar {
  --cui-sidebar-bg: #e7e6e6;
}

.sidebar-brand {
  --cui-sidebar-bg: #e7e6e6;
  --cui-sidebar-brand-bg: #e7e6e6;
}

.sidebar-nav .nav-link.active {
  color: #330099;
  font-weight: bold;
  background: var(--cui-sidebar-nav-link-active-bg);
}

.sidebar-nav .nav-link {
  color: #000000;
}

.sidebar-nav .nav-link:hover {
  color: #330099;
}
.sidebar-nav .nav-link.active .nav-icon {
  color: #330099;
}

.sidebar-nav .nav-link .nav-icon {
  color: #000000;
}
</style>
