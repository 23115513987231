import { createApp } from "vue";
import App from "./App.vue";
import router from "./router";
import { createPinia } from "pinia";
import mitt from "mitt";
import Toast from "vue-toastification";

import CoreuiVue from "@coreui/vue";
import CIcon from "@coreui/icons-vue";
import { iconsSet as icons } from "@/assets/icons";
import Datepicker from "@vuepic/vue-datepicker";

import "@vuepic/vue-datepicker/dist/main.css";
import "vue-toastification/dist/index.css";
import { QuillEditor } from '@vueup/vue-quill'
import '@vueup/vue-quill/dist/vue-quill.snow.css';

import VueAwesomePaginate from "vue-awesome-paginate";
import "vue-awesome-paginate/dist/style.css";






const options = {};

const pinia = createPinia();
const emitter = mitt();

const app = createApp(App);
app.config.globalProperties.emitter = emitter;
app.use(pinia);
app.use(router);
app.use(CoreuiVue);
app.use(Toast, options);
app.use(VueAwesomePaginate);
app.provide("icons", icons);
app.component("CIcon", CIcon);
app.component("Datepicker", Datepicker);
app.component('QuillEditor', QuillEditor);
app.mount("#app");
