import axios from "axios";

import { API_URL, TOKEN_NAME } from "@/configs";
import router from "@/router";
import { useUserStore } from "@/store/modules/user";

export default class Http {
  constructor(status) {
    const token = localStorage.getItem(TOKEN_NAME);

    const headers = status.auth ? { Authorization: `Bearer ${token}` } : {};

    this.instance = axios.create({
      baseURL: API_URL,
      headers: headers,
    });

    this.instance.interceptors.response.use(
      (response) => {
        if (response.status < 300) {
          return Promise.resolve(response);
        } else {
          return Promise.reject(response);
        }
      },
      (error) => {
        if (error.response.status) {
          switch (error.response.status) {
            case 401:
              const userStore = useUserStore();
              userStore.removeUser();

              router.replace({
                name: "auth",
              });
              break;
          }
          return Promise.reject(error.response);
        }
      }
    );

    return this.instance;
  }
}
