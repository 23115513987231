<template>
  <CHeader position="sticky" class="mb-4">
    <CContainer fluid>
      <CHeaderToggler class="ps-1" @click="globalStore.toggleSidebar">
        <CIcon icon="cil-menu" size="lg" />
      </CHeaderToggler>
      <CHeaderBrand class="mx-auto d-lg-none" to="/">
        <RouterLink :to="{ path: '/' }">
          <img src="@/assets/images/logo.svg" width="100" />
        </RouterLink>
      </CHeaderBrand>
      <CHeaderNav class="d-none d-md-flex me-auto"> </CHeaderNav>
      <CHeaderNav>
        <CNavItem>
          <CNavLink href="#">
            <CIcon class="mx-2" icon="cil-bell" size="lg" />
          </CNavLink>
        </CNavItem>

        <HeaderDropdownAccount />
      </CHeaderNav>
    </CContainer>
    <CHeaderDivider />
    <CContainer fluid>
      <Breadcrumb :links="globalStore.breadcrumbLinks" />
    </CContainer>
  </CHeader>
</template>

<script>

import HeaderDropdownAccount from "./HeaderDropdownAccount";
import Breadcrumb from "@/components/utilities/Breadcrumb.vue";
import { useGlobalStore } from "@/store/modules/global";
import { API_URL, TOKEN_NAME } from "@/configs";
import { useToast } from "vue-toastification";
export default {
  name: "Header",
  components: {
    HeaderDropdownAccount,
    Breadcrumb
  },
  setup() {
    const globalStore = useGlobalStore();
    const toast = useToast();



    return {
      globalStore,
    };
  },
};
</script>
